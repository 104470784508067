<template>
  <div id="mainSponsor">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 text-center">
          <p>{{ "sponsorsMainPage.brno" | staticTranslation }}</p>
          <div class="row">
            <div class="col-lg-4 d-flex justify-content-center align-items-center">
              <a href="https://www.brno.cz/uvodni-strana/"><img class="p-3 p-lg-0" src="@/assets/BRNO_logo_white.png" alt="BrnoLogo"></a>
            </div>
            <div class="col-lg-4 d-flex justify-content-center align-items-center">
              <a href=""><img class="p-3 p-lg-0" style="width: 70%" src="@/assets/jmk_bile.png" alt="JMKLogo"></a>
            </div>
            <div class="col-lg-4 d-flex justify-content-center align-items-center">
              <a href=""><img class="p-3 p-lg-0" src="@/assets/mkcr.png" alt="MKCRLogo"></a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 text-center">
          <p>{{ "sponsorsMainPage.tic" | staticTranslation }}</p>
          <div class="row">
            <div class="col-lg-12 d-flex justify-content-center align-items-center">
              <a href="https://ticbrno.cz/"><img src="@/assets/TIC_logo_white.png" alt="BrnoLogo"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="photoFooter">
      <div class="container"></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "mainSponsor"
}
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}

p {
  color: white;
  font-family: "Transcript Mono";
}

#mainSponsor {
  background-color: #1C191A;
  padding-top: 4.5em;
  padding-bottom: 2em;
}

.photoFooter {
  margin-top: 4em;
  padding-top: 6em;
  background-image: url("~@/assets/Paticka_I.jpeg");
  background-size: cover;
  background-attachment: fixed;
  height: 50vh;
}

@media screen and (max-width: 500px) {
  .photoFooter {
    padding-top: 1em;
    background-attachment: scroll;
    height: 80vh;
  }
}
</style>